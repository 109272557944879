import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import logo from './res/beringwaters-group-v.png';
import { useTheme } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';

const albumWindowDimentions = {width: 1440, height: 900};
const portretWindowDimentions = {width: 450};

export default function App() {
  const theme = useTheme();
  
  const { innerWidth: width, innerHeight: height } = window;
  const [windowDimensions, setWindowDimensions] = useState({width, height});
  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setWindowDimensions({width, height});
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isAlbum = windowDimensions.height < windowDimensions.width;
  const relation = windowDimensions.height / windowDimensions.width;
  const relationMuliplicator = relation >= 0.75 ? 1.2 : relation >= 0.625 ? 1.1 : 1;

  const zoomByWidth = (albumDimention: number, portretDimention: number) => isAlbum 
    ? albumDimention / albumWindowDimentions.width * windowDimensions.width * relationMuliplicator
    : portretDimention / portretWindowDimentions.width * windowDimensions.width;

  const zoomByHeight = (albumDimention: number, portretDimention: number) => isAlbum 
    ? albumDimention / albumWindowDimentions.height * windowDimensions.height
    : zoomByWidth(albumDimention, portretDimention);

  const titleFontSize = zoomByWidth(62, 42);
  const titleFontHeight = zoomByWidth(75, 50);
  const bodyFontSize = zoomByWidth(24, 14);
  const bodyFontHeight = zoomByWidth(29, 20);

  const logoHeight = zoomByHeight(43*2, 32);
  const logoWidth = zoomByHeight(147*2, 110);

  const mtTitle = zoomByWidth(0, 110);
  const mtBody = zoomByHeight(18, 10);
  const mtLink = zoomByHeight(10, 10);

  const boxPadding = zoomByHeight(30, 20);
  const boxBorder = zoomByHeight(5, 3);
  const boxMargin = zoomByWidth(0, 10);
  
  const typoStyle = {
    fontFamily: "Inter",
    letterSpacing: "0%",
    color: "#154482",
  };
  const titleStyle = {
    ...typoStyle,
    fontWeight: 800,
    fontSize: `${titleFontSize}px`,
    lineHeight: `${titleFontHeight}px`,
    marginTop: `${isAlbum ? mtBody : mtTitle}px`
  };
  const bodyBaseStyle = {
    ...typoStyle,
    fontWeight: 400,
    fontSize: `${bodyFontSize}px`,
    lineHeight: `${bodyFontHeight}px`,
  }
  const bodyStyle = {
    ...bodyBaseStyle,
    marginTop: `${mtBody}px`
  }
  const linkStyle: CSSProperties = {
    ...bodyBaseStyle,
    marginTop: `${mtLink}px`,
    textDecoration: "underline",
    textDecorationStyle: "solid",
    textDecorationThickness: "Auto",
    textDecorationSkipInk: "auto",
  }

  const boxStyle: CSSProperties = {
    padding: `${boxPadding}px`,
    border: `${boxBorder}px solid #154482`,
    display: "flex", 
    flexDirection: 'column',
    ...(
      isAlbum ? {
        width: "60vw", 
        height: "78vh", 
        marginLeft: "10vh",
      } : {
        margin: `${boxMargin}px`,
      }
    )
  };

  return (
    <Container sx={{m: 0}} style={{ padding: 0 }}>
      {isAlbum && <Box style={{ height: "10vh" }} />}
      <Box style={boxStyle}> 
        <Box
          component="img"
          sx={{
            height: logoHeight,
            width: logoWidth,
            maxHeight: logoHeight,
            maxWidth: logoWidth,
          }}
          src={logo}
        />
        {isAlbum && <Box sx={{ flex: 1 }}/>}
        <Typography style={titleStyle} >
          Bering Waters Group
        </Typography>
        <Typography style={bodyStyle}>
          {"Bering Waters Group is a strategic investment and advisory group focused on supporting the next generation of blockchain innovators. Specializing in early-stage investments, we provide not only capital but also hands-on advisory, facilitate strategic partnerships, and help projects build relationships with investors—to ensure their success and scalability."}
        </Typography>
        <Typography style={bodyStyle}>
          {"Our commitment extends beyond investment—we actively collaborate with our portfolio companies and broader market participants to drive sustainable growth and long-term impact, while contributing to the overall advancement of the blockchain industry in an evolving and competitive landscape."}
        </Typography>
        <Link style={linkStyle} href="/hello">contact</Link>
      </Box>
      {isAlbum && <Box style={{ height: "12vh" }} />}
    </Container>
  );
}
